import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Project } from '../../types/type';
import "./style.scss"

interface ProjectListProps {
    projects: Project[];
}

const ProjectList: React.FC<ProjectListProps> = ({ projects }) => {
    const navigate = useNavigate();

    return (
        <Box className="project-list" sx={{ width: { xs: "88%", sm: "80%", md: "65%" } }}>
            {projects.map((project) => (
                <Card
                    onClick={() => {
                        window.scrollTo(0, 0);
                        navigate(`/projects/${project.id}`)
                    }}
                    className='project-card'
                    key={project.id}
                    sx={{
                        backgroundImage: `url(${project.cover})`,
                    }}
                >
                    <CardContent sx={{ position: 'absolute', bottom: 0, width: '100%', display: "flex", flexDirection: "column", gap: "5px" }}>
                        <Typography variant="h5" sx={{
                            fontFamily: "lemon Milk",
                            fontWeight: "900",
                            fontSize: { xs: "1.2rem", sm: "1.2rem", md: "1.5rem" }
                        }}>
                            {project.title}
                        </Typography>
                        <Typography variant="body2" sx={{ fontFamily: "Lemon Milk Pro", fontSize: { xs: "1rem", sm: "1rem", md: "1.2rem" } }}>
                            LOCATION: {project.location}
                        </Typography>
                        <Typography variant="body2" sx={{ fontFamily: "Lemon Milk Pro", fontSize: { xs: "1rem", sm: "1rem", md: "1.2rem" } }}>
                            SIZE: {project.sizeInSqFt} SQ FT
                        </Typography>
                    </CardContent>
                </Card>
            ))}
        </Box>
    );
};

export default ProjectList;
