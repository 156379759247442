import { Typography } from '@mui/material';
import React from 'react';

interface TitleProps {
  text: string;
  textAlign?: 'left' | 'center' | 'right';
  color?: string;
  textShadow?: string;
  fontSize?: { xs: string; sm: string; md: string } | string;
  fontWeight?: string
}

const Title: React.FC<TitleProps> = ({ text, textAlign, color, textShadow, fontSize, fontWeight }) => {
  return (
    <Typography 
      variant='h4'
      component='h3'
      sx={{ 
        fontSize: fontSize || { xs: '1.8rem', sm: '2.1rem', md: '2.1rem' },
        fontWeight:fontWeight || '800',
        textAlign: textAlign,
        color: color,
        textShadow: textShadow,
        fontFamily: 'Lemon Milk, sans-serif'
      }}
    >
      {text}
    </Typography>
  );
}

export default Title;
