import React from 'react';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import { lazy,Suspense } from 'react';
import './App.css';
//components
import Navbar from './components/navbar';
import Home from './pages/home';
import Footer from './components/footer';

const About = lazy(() => import('./pages/about'));
const Contact = lazy(() => import('./pages/contact/index'));
const Services = lazy(() => import('./pages/services'));
const Projects = lazy(() => import('./pages/projects'));
const ProjectDetail = lazy(() => import('./pages/projectDetail'));


function App() {
  return (
    <>
    <BrowserRouter>
    <Navbar/>
    <Suspense fallback={<div>Loading...</div>}>
    <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/about' element={<About/>}/>
      <Route path='/contact' element={<Contact/>}/>
      <Route path='/services' element={<Services/>}/>
      <Route path='/projects' element={<Projects/>}/>
      <Route path='/projects/:id' element={<ProjectDetail/>}/>
    </Routes>
    </Suspense>
    <Footer/>
    </BrowserRouter>
    </>
  );
}

export default App;
