import { Box, Typography } from "@mui/material"

interface textProps {
    text: string
}

const CoverText = ({ text }: textProps) => {
    return (
        <Box className="banner-text" >
            <Typography
                variant='h4'
                color='#fff'
                sx={{
                    width: { xs: "100%", md: "55%" },
                    fontSize: { md: "3rem", xs: "2rem" },
                    fontFamily:"Lemon Milk Pro"
                }}>{text}</Typography>
        </Box>
    )
}

export default CoverText;