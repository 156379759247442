import { Typography } from '@mui/material';
import React from 'react';

interface ParagraphProps {
  text: string | undefined;
  maxWidth?: string | number;
  mx?: string | number;
  textAlign?: 'left' | 'center' | 'right';
  color?: string;
  fontFamily?:string;
  fontWeight?:string;
  fontSize?: number;
}

const Paragraph: React.FC<ParagraphProps> = ({ text, maxWidth, mx, textAlign, color,fontFamily,fontWeight,fontSize }) => {
  return (
    <Typography
      sx={{
        maxWidth: maxWidth,
        mx: mx,
        textAlign: textAlign,
        py: 3,
        color: color || '#7b7b7b',
        fontFamily:'Lemon Milk Pro',
        fontWeight: fontWeight || "650",
        fontSize : fontSize ||  { xs: '1rem', sm: '1.5rem', md: '1.4rem' }
      }}
    >
      {text}
    </Typography>
  );
};

export default Paragraph;
