import React, { useState } from 'react';
import { Drawer, Box, } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Title from '../../text/Title';
import ContactFormFields from "../../contactForm/formFields";
import logoWhite from "../../../assets/logo/Zumar Logo White.png"
import SuccessDialog from '../../dialogBox';
import SocialMediaLinks from '../../socialMediaLinks';
//icons
import MailIcon from '@mui/icons-material/Mail';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PhoneIcon from '@mui/icons-material/Phone';

interface DrawerComponentProps {
    open: boolean;
    onClose: (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const BlurredBackground = styled(Box)(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    zIndex: theme.zIndex.drawer - 1,
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiDrawer-paper': {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '50%',
        },
        [theme.breakpoints.up('md')]: {
            width: '30%',
        },
    },
}));

const ResponsiveLogo = styled('img')(({ theme }) => ({
    marginTop: "10%",
    maxWidth: '60%',
    height: 'auto',
    [theme.breakpoints.up('sm')]: {
        maxWidth: '65%',
    },
    [theme.breakpoints.up('md')]: {
        maxWidth: '55%',
    },
}));

const ContactDrawer: React.FC<DrawerComponentProps> = ({ open, onClose }) => {
    const [isOpen, setOpen] = useState(false);

    const handleFormSubmit = (values: any) => {
        console.log(values);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    const drawerContent = (
        <Box className="drawer-content" sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }} onClick={(event) => onClose(event)}>
                <IconButton>
                    <CloseIcon fontSize='large' sx={{ color: "#fff" }} />
                </IconButton>
            </Box>
            <Box className='drawer-items' sx={{ gap: { md: "10%", xs: "5%" } }}>
                <ResponsiveLogo
                    src={logoWhite}
                    alt="Logo"
                />
                <Box sx={{
                    width: "90% !important"
                }}
                >
                    <ContactFormFields onSubmit={handleFormSubmit} />
                </Box>
                <Box>
                    <Box className="flex-row-center">
                        <IconButton href="+91 9445442006" sx={{ color: 'grey' }}>
                            <PhoneIcon />
                        </IconButton>
                        <Title text={"+91 9445442006"} color={"grey"} fontSize={"18px"} fontWeight={"0"} />
                    </Box>
                    <Box className="flex-row-center">
                        <IconButton href="mailto:info@zumarec.com" sx={{ color: 'grey' }}>
                            <MailIcon />
                            <Title text={"info@zumarec.com"} color={"grey"} fontSize={"18px"} fontWeight={"lighter"} />
                        </IconButton>
                    </Box>
                    <Box className="flex-row-center">
                    <SocialMediaLinks btnColor='#fff' /> 
                    </Box> 
                </Box>
            </Box>
        </Box>
    );

    return (
        <>
            {open && <BlurredBackground />}
            <StyledDrawer
                anchor="left"
                open={open}
                onClose={onClose}
            >
                {drawerContent}
            </StyledDrawer>
            <SuccessDialog
                open={isOpen}
                onClose={handleClose}
            />
        </>
    );
};

export default ContactDrawer;