import { Box, TextField, Button } from "@mui/material";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FormValues } from "../../types/type";

interface ContactFormFieldsProps {
  onSubmit: (values: FormValues) => void;
}

const FormFields: React.FC<ContactFormFieldsProps> = ({ onSubmit }) => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phone: Yup.string()
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
      .required("Phone number is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      text: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      onSubmit(values);
      resetForm();
    },
  });

  return (
    <Box
      className="form flex-col-center"
      component="form"
      noValidate
      onSubmit={formik.handleSubmit}
      sx={{ width: { xs: "100%", sm: "100%", md: "60%" } }}
    >
      <TextField
        className="custom-textfield"
        required
        fullWidth
        id="name"
        label="NAME"
        name="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        variant="standard"
      />
      <TextField
        className="custom-textfield"
        fullWidth
        id="email"
        label="EMAIL ADDRESS"
        name="email"
        autoComplete="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        variant="standard"
      />
      <TextField
        className="custom-textfield"
        required
        fullWidth
        name="phone"
        label="PHONE NUMBER"
        type="phone"
        id="phone"
        autoComplete="current-phone"
        value={formik.values.phone}
        onChange={formik.handleChange}
        error={formik.touched.phone && Boolean(formik.errors.phone)}
        helperText={formik.touched.phone && formik.errors.phone}
        variant="standard"
      />
      <TextField
        className="custom-textfield"
        id="standard-multiline-static"
        label="HOW CAN WE HELP?"
        multiline
        name="text"
        rows={4}
        value={formik.values.text}
        onChange={formik.handleChange}
        variant="standard"
      />
      <Button
        variant="outlined"
        fullWidth
        type="submit"
        size="medium"
        sx={{
          fontSize: "0.9rem",
          color: "#B3B5B5",
          py: 2,
          mt: 3,
          mb: 2,
          borderRadius: 0,
          borderColor: "#B3B5B5",
          "&:hover": {
            backgroundColor: "#fff",
            color: "#000"
          },
        }}
      >
        SUBMIT
      </Button>
    </Box>
  );
};

export default FormFields;
