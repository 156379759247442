import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
//img
import logoBlack from "../../assets/logo/Zumar Logo Black.png"
import logoWhite from "../../assets/logo/Zumar Logo White.png"
import DrawerComponent from '../drawer/nav';
import { useNavigate } from 'react-router-dom';

interface StyledAppBarProps {
  isScrolled: boolean;
}

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isScrolled',
})<StyledAppBarProps>(({ theme, isScrolled }) => ({
  transition: theme.transitions.create(['background-color', 'color', 'height'], {
    duration: theme.transitions.duration.standard,
  }),
  backgroundColor: isScrolled ? theme.palette.background.paper : 'transparent',
  color: isScrolled ? theme.palette.text.primary : theme.palette.common.white,
  boxShadow: isScrolled ? theme.shadows[1] : 'none',
  height: isScrolled ? 82 : 100,
  padding: "0 15%",
  [theme.breakpoints.down('sm')]: {
    padding: "0",
  },

}));

const StyledToolbar = styled(Toolbar)<StyledAppBarProps>(({ isScrolled }) => ({
  height: "100%",
  justifyContent: "space-between",
  transition: 'height 0.3s ease-in-out',
  display: 'flex',
  alignItems: 'center',
}));

const Logo = styled('img')<StyledAppBarProps>(({ theme, isScrolled }) => ({
  height: isScrolled ? 55 : 65,
  [theme.breakpoints.down('sm')]: {
    height: isScrolled ? 40 : 45,
  },
  transition: 'height 0.3s ease-in-out',
}));

const StyledSVG = styled('svg')<StyledAppBarProps>(({ isScrolled }) => ({
  width: '42px',
  height: '45px',
  transition: 'fill 0.3s ease-in-out, stroke 0.3s ease-in-out',
  fill: 'none',
}));

const Navbar: React.FC = () => {
  const [scrolled, setScrolled] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 50;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <>
      <StyledAppBar position="fixed" isScrolled={scrolled}>
        <StyledToolbar isScrolled={scrolled}>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }} >
            <Logo
              onClick={() =>{ 
                window.scrollTo(0, 0);
                navigate("/")}}
              src={scrolled ? logoBlack : logoWhite}
              alt="Logo"
              isScrolled={scrolled}
            />
          </Box>
          <Box onClick={toggleDrawer(true)}>
            <StyledSVG
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 55 42"
              isScrolled={scrolled}
            >
              <g stroke={scrolled ? "#000" : "#fff"} strokeLinecap="round" strokeWidth="3.2">
                <path d="M1.25 1.25h52.5v1h-52.5z"></path>
                <path d="M32.25 20.25h22v1h-22z"></path>
                <path d="M13.25 39.25h40.5v1h-40.5z"></path>
              </g>
            </StyledSVG>
          </Box>
        </StyledToolbar>
      </StyledAppBar>
      <DrawerComponent open={drawerOpen} onClose={toggleDrawer(false)} />
    </>
  );
};

export default Navbar;