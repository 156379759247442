import React from 'react';
import { Box, styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Facebook, Instagram, LinkedIn } from '@mui/icons-material';

const CustomIconButton = styled(IconButton)<{ btnColor?: string }>(
  ({ btnColor }) => ({
    color: btnColor || '#000',
  })
);

interface SocialMediaLinksProps {
  btnColor?: string;
}

const SocialMediaLinks: React.FC<SocialMediaLinksProps> = ({ btnColor }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
      <CustomIconButton aria-label="Facebook" btnColor={btnColor} onClick={() => window.open("https://www.facebook.com/people/Zumar-Engineering-and-Construction/100065187866316/")}>
        <Facebook />
      </CustomIconButton>
      <CustomIconButton aria-label="Instagram" btnColor={btnColor} onClick={() => window.open("https://www.instagram.com/zumar_constructions/")}>
        <Instagram />
      </CustomIconButton>
      <CustomIconButton aria-label="LinkedIn" btnColor={btnColor} onClick={() => window.open("https://www.linkedin.com/company/zumar-engineering-and-constructions/")}>
        <LinkedIn />
      </CustomIconButton>
    </Box>
  );
};

export default SocialMediaLinks;
