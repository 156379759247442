import { useState, useEffect } from 'react';
import { Box, Button } from "@mui/material";
import CoverText from '../../components/cover/coverText';
import BlockContent from '../../components/blockContent';
import ProjectList from '../../components/projectList';
import ContactForm from '../../components/contactForm';
import { Project } from '../../types/type';
//img
import img1 from "../../assets/images/carousel/home/pexels-pixabay-269077.jpg";
import img2 from "../../assets/images/carousel/home/architecture-2178978_1280.jpg";
import img3 from "../../assets/images/carousel/home/pexels-pixabay-37347.jpg";
const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [img1, img2, img3];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [currentIndex, images.length]);

  const projects: Project[] = [
    {
      id: 1,
      title: "Modern Office Building",
      cover: "https://cdn.pixabay.com/photo/2019/04/02/20/45/landscape-4098802_1280.jpg",
      location: "CHENNAI,TN",
      sizeInSqFt: 50000
    },
    {
      id: 2,
      title: "Modern House",
      cover: "https://cdn.pixabay.com/photo/2019/04/02/20/45/landscape-4098802_1280.jpg",
      location: "TRIVANDRAM,KL",
      sizeInSqFt: 40000
    },
  ];

  return (
    <Box>
      <Box sx={{ height: "100vh", position: "relative", overflow: "hidden" }}>
        {images.map((image, index) => (
          <Box
            key={index}
            component="img"
            src={image}
            alt={`Image ${index}`}
            sx={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              opacity: index === currentIndex ? 1 : 0,
              transition: "opacity 0.5s ease-in-out",
              objectFit: "cover"
            }}
          />
        ))}
      </Box>
      <CoverText text='ZUMAR-A CONSTRUCTION COMPANY WITH PRECISION ENGINNERING FOR SUPERIOR CONSTRUCTION' />
      <BlockContent title={'Vision'} paragraph={'Building innovative, sustainable and high-quality solutions that shape a better future'} />
      <BlockContent title={'mission'} paragraph={'To provide reliable and innovative construction services, ensuring every project is delivered on time with top tier quality and focus on exceeding client expectations'} bgColor='#F8F8F8' />
      <BlockContent title={'projects'} paragraph={'Explore our portfolio of completed projects that showcase our commitment to quality and dedication to craftmanship'} />
      <ProjectList projects={projects} />
      <Button href='/projects' variant="outlined" sx={{ color: 'black', fontSize: { xs: "1rem", md: "1.2rem" }, width: { xs: "30%", md: "10%" } }} id='projects-button'>
        PROJECTS
      </Button>
      <BlockContent title={'we innovate'} paragraph={'We have a proven track record in having you covered from structural design to execution and interior works'} bgColor='#F8F8F8' />
      <ContactForm />
    </Box>
  );
};

export default Home;