import React, { useState } from 'react';
import { Box, Button, Container, List, ListItemButton, Stack, Typography, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import ContactDrawer from '../drawer/contact';
import logo from "../../assets/logo/Zumar Logo Black.png"
import "./style.scss"
import SocialMediaLinks from '../socialMediaLinks';


const Footer: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const itemList = [
    { text: "ABOUT", to: "/about" },
    { text: "SERVICES", to: "/services" },
    { text: "PROJECTS", to: "/project" },
    { text: "CONTACT", to: "/contact" }
  ];

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <>
      <Box component="footer" sx={{ py: 8, backgroundColor: '#f8f8f8' }}>
        <Container maxWidth="lg">
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="center"
            justifyContent="space-between"
            spacing={{ xs: 2, sm: 4 }}
          >
            {/* Logo */}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
              <img
                src={logo}
                alt="Logo"
                style={{ height: 50, marginRight: 16 }}
              />
            </Box>

            {/* Page Links */}
            <List sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, alignItems: "center" }}>
              {itemList.map((item) => {
                const { text, to } = item;
                return (
                  <ListItemButton
                    key={text}
                    component={Link}
                    to={to}
                    onClick={(event) => {
                      window.scrollTo(0, 0);
                    }}
                    sx={{
                      color: "#000",
                      "&:hover": {
                        color: '#fff',
                      }
                    }}
                  ><Typography id="footer-link" sx={{ fontSize: { xs: "1rem", md: "1.2rem" } }}>{text}</Typography>
                  </ListItemButton>
                )
              })}
            </List>
            {/* Request Callback Button */}
            <Box sx={{ textAlign: { xs: 'center', sm: 'right' } }} onClick={toggleDrawer(true)}>
              <Button variant="outlined" sx={{ borderColor: "black", color: "black", borderRadius: 0, fontSize: "1rem", fontFamily: "Lemon Milk Pro" }} >
                Request Callback
              </Button>
            </Box>
          </Stack>
        </Container>
        <Divider sx={{ my: 3, backgroundColor: 'grey' }} />
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems="center"
          justifyContent="center"
          spacing={{ xs: 2, sm: 4 }}
        >
          {/* Social Media Icons */}
         <SocialMediaLinks/>
          {/* Copyright Info */}
          <Typography variant="body2" sx={{ textAlign: { xs: 'center', sm: 'right' }, color: '#7b7b7b' }}>
            © {new Date().getFullYear()} Zumar Engineering and Constructions.
          </Typography>
        </Stack>
      </Box>
      <ContactDrawer open={drawerOpen} onClose={toggleDrawer(false)} />
    </>
  );
};

export default Footer;
